import React from "react";
import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import RightSidebar from "../components/RightSidebar.js";

const TermsPage = () => {
  return (
    <>
      <Head title="Terms & Conditions" description="Terms & Conditions" />

      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Terms & Conditions</h1>
              <div className="wrapping-terms">
                <div classnope="wrapping">
                  <div>
                    <br />
                  </div>
                  <div data-custom-classnope="heading_1" id="agreement">
                    <strong>
                      <span>1. AGREEMENT TO TERMS</span>
                    </strong>
                  </div>
                  <div>
                    <br />
                  </div>
                  <div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          These Terms of Use constitute a legally binding
                          agreement made between you, whether personally or on
                          behalf of an entity (“you”) and
                          <span>austincitytaco</span>("<strong>Company</strong>
                          ," “<strong>we</strong>," “<strong>us</strong>," or “
                          <strong>our</strong>”), concerning your access to and
                          use of the{" "}
                          <a href="https://www.austincitytaco.com/">
                            austincitytaco.com
                          </a>{" "}
                          website as well as any other media form, media
                          channel, mobile website or mobile application related,
                          linked, or otherwise connected thereto (collectively,
                          the “Site”).
                          <span>
                            <span>
                              <div classnope="question"></div>
                            </span>
                          </span>
                        </span>
                        You agree that by accessing the Site, you have read,
                        understood, and agreed to be bound by all of these Terms
                        of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF
                        USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
                        SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          Supplemental terms and conditions or documents that
                          may be posted on the Site from time to time are hereby
                          expressly incorporated herein by reference. We reserve
                          the right, in our sole discretion, to make changes or
                          modifications to these Terms of Use at any time and
                          for any reason . We will alert you about any changes
                          by updating the “Last updated” date of these Terms of
                          Use, and you waive any right to receive specific
                          notice of each such change. Please ensure that you
                          check the applicable Terms every time you use our Site
                          so that you understand which Terms apply. You will be
                          subject to, and will be deemed to have been made aware
                          of and to have accepted, the changes in any revised
                          Terms of Use by your continued use of the Site after
                          the date such revised Terms of Use are posted.
                        </span>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          The information provided on the Site is not intended
                          for distribution to or use by any person or entity in
                          any jurisdiction or country where such distribution or
                          use would be contrary to law or regulation or which
                          would subject us to any registration requirement
                          within such jurisdiction or country. Accordingly,
                          those persons who choose to access the Site from other
                          locations do so on their own initiative and are solely
                          responsible for compliance with local laws, if and to
                          the extent local laws are applicable.
                        </span>
                      </div>
                      <div>
                        <span>
                          <span></span>
                        </span>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          <div classnope="question">__________</div>
                        </span>
                      </div>
                      <div>
                        <span>
                          <span>
                            <div classnope="statement-end-if-in-editor">
                              <span>
                                <div classnope="statement-end-if-in-editor"></div>
                              </span>
                            </div>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <div data-type="conditional-block">
                          <div
                            classnope="block-component"
                            data-record-question-key="user_o18_option"
                            data-type="statement"
                          >
                            <span></span>
                          </div>
                          <div
                            classnope="block-container if"
                            data-type="if"
                            id="a2595956-7028-dbe5-123e-d3d3a93ed076"
                          >
                            <div data-type="conditional-block">
                              <div data-type="body">
                                <span>
                                  All users who are minors in the jurisdiction
                                  in which they reside (generally under the age
                                  of 18) must have the permission of, and be
                                  directly supervised by, their parent or
                                  guardian to use the Site. If you are a minor,
                                  you must have your parent or guardian read and
                                  agree to these Terms of Use prior to you using
                                  the Site.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          classnope="statement-end-if-in-editor"
                          data-type="close"
                        >
                          <span></span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div>
                        <br />
                      </div>
                      <div data-custom-classnope="heading_1" id="ip">
                        <strong>
                          <span>
                            <strong>
                              <span>2.&nbsp;</span>
                            </strong>
                            INTELLECTUAL PROPERTY RIGHTS
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          Unless otherwise indicated, the Site is our
                          proprietary property and all source code, databases,
                          functionality, software, website designs, audio,
                          video, text, photographs, and graphics on the Site
                          (collectively, the “Content”) and the trademarks,
                          service marks, and logos contained therein (the
                          “Marks”) are owned or controlled by us or licensed to
                          us, and are protected by copyright and trademark laws
                          and various other intellectual property rights and
                          unfair competition laws of the United States,
                          international copyright laws, and international
                          conventions. The Content and the Marks are provided on
                          the Site “AS IS” for your information and personal use
                          only. Except as expressly provided in these Terms of
                          Use, no part of the Site and no Content or Marks may
                          be copied, reproduced, aggregated, republished,
                          uploaded, posted, publicly displayed, encoded,
                          translated, transmitted, distributed, sold, licensed,
                          or otherwise exploited for any commercial purpose
                          whatsoever, without our express prior written
                          permission.
                        </span>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          Provided that you are eligible to use the Site, you
                          are granted a limited license to access and use the
                          Site and to download or print a copy of any portion of
                          the Content to which you have properly gained access
                          solely for your personal, non-commercial use. We
                          reserve all rights not expressly granted to you in and
                          to the Site, the Content and the Marks.
                        </span>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div>
                        <br />
                      </div>
                      <div data-custom-classnope="heading_1" id="userreps">
                        <strong>
                          <span>
                            <strong>
                              <span>
                                <strong>
                                  <span>3.</span>
                                </strong>
                              </span>
                              &nbsp;
                            </strong>
                            USER REPRESENTATIONS
                          </span>
                        </strong>
                      </div>
                      <div>
                        <br />
                      </div>
                    </div>
                    <div>
                      <span>
                        <span data-custom-classnope="body_text">
                          <span>
                            By using the Site, you represent and warrant that:
                          </span>
                          <span
                            classnope="block-container if"
                            data-type="if"
                            id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
                          >
                            <span data-type="conditional-block"></span>
                            <span>(</span>
                            <span>1</span>
                            <span>
                              ) you have the legal capacity and you agree to
                              comply with these Terms of Use;
                            </span>
                            <span
                              classnope="block-container if"
                              data-type="if"
                              id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
                            >
                              <span data-type="conditional-block"></span>
                            </span>
                          </span>
                          <span>&nbsp;(</span>
                          <span>2</span>
                          <span>
                            ) you are not a minor in the jurisdiction in which
                            you reside
                            <span
                              classnope="block-container if"
                              data-type="if"
                              id="76948fab-ec9e-266a-bb91-948929c050c9"
                            >
                              <span data-type="conditional-block">
                                <span data-type="body">
                                  , or if a minor, you have received parental
                                  permission to use the Site
                                </span>
                              </span>
                            </span>
                            ; (
                          </span>
                          <span>3</span>
                          <span>
                            ) you will not access the Site through automated or
                            non-human means, whether through a bot, script, or
                            otherwise; (
                          </span>
                          <span>4</span>
                          <span>
                            ) you will not use the Site for any illegal or
                            unauthorized purpose; and (
                          </span>
                          <span>5</span>
                          <span>
                            ) your use of the Site will not violate any
                            applicable law or regulation.
                          </span>
                          <span></span>
                        </span>
                      </span>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div data-custom-classnope="body_text">
                          <span>
                            If you provide any information that is untrue,
                            inaccurate, not current, or incomplete, we have the
                            right to suspend or terminate your account and
                            refuse any and all current or future use of the Site
                            (or any portion thereof).
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div data-type="conditional-block">
                          <div
                            classnope="block-component"
                            data-record-question-key="user_account_option"
                            data-type="statement"
                          >
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div data-custom-classnope="heading_1" id="prohibited">
                          <strong>
                            <span>
                              <strong>
                                <span>
                                  <strong>
                                    <span>4.</span>
                                  </strong>
                                </span>
                                &nbsp;
                              </strong>
                              PROHIBITED ACTIVITIES
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div data-custom-classnope="body_text">
                          <span>
                            You may not access or use the Site for any purpose
                            other than that for which we make the Site
                            available. The Site may not be used in connection
                            with any commercial endeavors except those that are
                            specifically endorsed or approved by us.
                          </span>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div>
                          <div>
                            <div data-custom-classnope="body_text">
                              <span>
                                As a user of the Site, you agree not to:
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  Systematically retrieve data or other content
                                  from the Site to create or compile, directly
                                  or indirectly, a collection, compilation,
                                  database, or directory without written
                                  permission from us.
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Trick, defraud, or mislead us and other
                                        users, especially in any attempt to
                                        learn sensitive account information such
                                        as user passwords.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Circumvent, disable, or otherwise
                                        interfere with security-related features
                                        of the Site, including features that
                                        prevent or restrict the use or copying
                                        of any Content or enforce limitations on
                                        the use of the Site and/or the Content
                                        contained therein.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Disparage, tarnish, or otherwise harm,
                                        in our opinion, us and/or the Site.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Use any information obtained from the
                                        Site in order to harass, abuse, or harm
                                        another person.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Make improper use of our support
                                        services or submit false reports of
                                        abuse or misconduct.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Use the Site in a manner inconsistent
                                        with any applicable laws or regulations.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Engage in unauthorized framing of or
                                        linking to the Site.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Upload or transmit (or attempt to upload
                                        or to transmit) viruses, Trojan horses,
                                        or other material, including excessive
                                        use of capital letters and spamming
                                        (continuous posting of repetitive text),
                                        that interferes with any party’s
                                        uninterrupted use and enjoyment of the
                                        Site or modifies, impairs, disrupts,
                                        alters, or interferes with the use,
                                        features, functions, operation, or
                                        maintenance of the Site.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Engage in any automated use of the
                                        system, such as using scripts to send
                                        comments or messages, or using any data
                                        mining, robots, or similar data
                                        gathering and extraction tools.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Delete the copyright or other
                                        proprietary rights notice from any
                                        Content.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Attempt to impersonate another user or
                                        person or use the username of another
                                        user.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Upload or transmit (or attempt to upload
                                        or to transmit) any material that acts
                                        as a passive or active information
                                        collection or transmission mechanism,
                                        including without limitation, clear
                                        graphics interchange formats (“gifs”),
                                        1×1 pixels, web bugs, cookies, or other
                                        similar devices (sometimes referred to
                                        as “spyware” or “passive collection
                                        mechanisms” or “pcms”).
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Interfere with, disrupt, or create an
                                        undue burden on the Site or the networks
                                        or services connected to the Site.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Harass, annoy, intimidate, or threaten
                                        any of our employees or agents engaged
                                        in providing any portion of the Site to
                                        you.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Attempt to bypass any measures of the
                                        Site designed to prevent or restrict
                                        access to the Site, or any portion of
                                        the Site.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Copy or adapt the Site’s software,
                                        including but not limited to Flash, PHP,
                                        HTML, JavaScript, or other code.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Except as permitted by applicable law,
                                        decipher, decompile, disassemble, or
                                        reverse engineer any of the software
                                        comprising or in any way making up a
                                        part of the Site.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Except as may be the result of standard
                                        search engine or Internet browser usage,
                                        use, launch, develop, or distribute any
                                        automated system, including without
                                        limitation, any spider, robot, cheat
                                        utility, scraper, or offline reader that
                                        accesses the Site, or using or launching
                                        any unauthorized script or other
                                        software.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Use a buying agent or purchasing agent
                                        to make purchases on the Site.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Make any unauthorized use of the Site,
                                        including collecting usernames and/or
                                        email addresses of users by electronic
                                        or other means for the purpose of
                                        sending unsolicited email, or creating
                                        user accounts by automated means or
                                        under false pretenses.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                            <li data-custom-classnope="body_text">
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        Use the Site as part of any effort to
                                        compete with us or otherwise use the
                                        Site and/or the Content for any
                                        revenue-generating endeavor or
                                        commercial enterprise.
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <div classnope="forloop-component"></div>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div>
                          <br />
                        </div>
                        <div>
                          <br />
                        </div>
                        <div>
                          <div data-type="conditional-block">
                            <div data-type="body">
                              <div data-custom-classnope="heading_1" id="ugc">
                                <strong>
                                  <span>
                                    <strong>
                                      <span>
                                        <strong>
                                          <span>5.</span>
                                        </strong>
                                      </span>
                                      &nbsp;
                                    </strong>
                                    USER GENERATED CONTRIBUTIONS
                                  </span>
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <br />
                        </div>
                        <div>
                          <div data-type="conditional-block">
                            <div data-type="body">
                              <div data-custom-classnope="body_text">
                                <span>
                                  The Site does not offer users to submit or
                                  post content. We may provide you with the
                                  opportunity to create, submit, post, display,
                                  transmit, perform, publish, distribute, or
                                  broadcast content and materials to us or on
                                  the Site, including but not limited to text,
                                  writings, video, audio, photographs, graphics,
                                  comments, suggestions, or personal information
                                  or other material (collectively,
                                  "Contributions"). Contributions may be
                                  viewable by other users of the Site and
                                  through third-party websites. As such, any
                                  Contributions you transmit may be treated in
                                  accordance with the Site Privacy Policy. When
                                  you create or make available any
                                  Contributions, you thereby represent and
                                  warrant that:<span></span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div data-type="conditional-block">
                            <div data-type="body">
                              <ul>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    The creation,
                                    <span>
                                      distribution, transmission, public
                                      display, or performance, and the
                                      accessing, downloading, or copying of your
                                      Contributions do not and will not infringe
                                      the proprietary rights, including but not
                                      limited to the copyright, patent,
                                      trademark, trade secret, or moral rights
                                      of any third party.
                                    </span>
                                  </span>
                                </li>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    <span>
                                      You are the creator and owner of or have
                                      the necessary licenses, rights, consents,
                                      releases, and permissions to use and to
                                      authorize us, the Site, and other users of
                                      the Site to use your Contributions in any
                                      manner contemplated by the Site and these
                                      Terms of Use.
                                    </span>
                                  </span>
                                </li>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    You have the written consent, release,
                                    and/or permission of each and every
                                    identifiable individual person in your
                                    Contributions to use the name or likeness of
                                    each and every such identifiable individual
                                    person to enable inclusion and use of your
                                    Contributions in any manner contemplated by
                                    the Site and these Terms of Use.
                                  </span>
                                </li>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    Your Contributions are not false,
                                    inaccurate, or misleading.
                                  </span>
                                </li>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    Your Contributions are not unsolicited or
                                    unauthorized advertising, promotional
                                    materials, pyramid schemes, chain letters,
                                    spam, mass mailings, or other forms of
                                    solicitation.
                                  </span>
                                </li>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    Your Contributions are not obscene, lewd,
                                    lascivious, filthy, violent, harassing,
                                    libelous, slanderous, or otherwise
                                    objectionable (as determined by us).
                                  </span>
                                </li>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    Your Contributions do not ridicule, mock,
                                    disparage, intimidate, or abuse anyone.
                                  </span>
                                </li>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    Your Contributions are not used to harass or
                                    threaten (in the legal sense of those terms)
                                    any other person and to promote violence
                                    against a specific person or class of
                                    people.
                                  </span>
                                </li>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    Your Contributions do not violate any
                                    applicable law, regulation, or rule.
                                  </span>
                                </li>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    Your Contributions do not violate the
                                    privacy or publicity rights of any third
                                    party.
                                  </span>
                                </li>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    Your Contributions do not violate any
                                    applicable law concerning child pornography,
                                    or otherwise intended to protect the health
                                    or well-being of minors.
                                  </span>
                                </li>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    Your Contributions do not include any
                                    offensive comments that are connected to
                                    race, national origin, gender, sexual
                                    preference, or physical handicap.
                                  </span>
                                </li>
                                <li data-custom-classnope="body_text">
                                  <span>
                                    Your Contributions do not otherwise violate,
                                    or link to material that violates, any
                                    provision of these Terms of Use, or any
                                    applicable law or regulation.
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div data-type="conditional-block">
                            <div data-type="body">
                              <div data-custom-classnope="body_text">
                                <span>
                                  Any use of the Site in violation of the
                                  foregoing violates these Terms of Use and may
                                  result in, among other things, termination or
                                  suspension of your rights to use the Site.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div>
                          <div data-type="conditional-block">
                            <div data-type="body">
                              <div
                                data-custom-classnope="heading_1"
                                id="license"
                              >
                                <strong>
                                  <span>
                                    <strong>
                                      <span>
                                        <strong>
                                          <span>6.</span>
                                        </strong>
                                      </span>
                                      &nbsp;
                                    </strong>
                                    CONTRIBUTION LICENSE
                                  </span>
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div data-type="conditional-block">
                            <div data-type="body">
                              <div>
                                <span></span>
                              </div>
                              <div>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div data-custom-classnope="body_text">
                          <span>
                            You agree that we may access, store, process, and
                            use any information and personal data that you
                            provide following the terms of the Privacy Policy
                            and your choices (including settings).
                          </span>
                        </div>
                        <div>
                          <br />
                        </div>
                        <div data-custom-classnope="body_text">
                          <span>
                            By submitting suggestions or other feedback
                            regarding the Site, you agree that we can use and
                            share such feedback for any purpose without
                            compensation to you.
                          </span>
                        </div>
                        <div>
                          <br />
                        </div>
                        <div data-custom-classnope="body_text">
                          <span>
                            We do not assert any ownership over your
                            Contributions. You retain full ownership of all of
                            your Contributions and any intellectual property
                            rights or other proprietary rights associated with
                            your Contributions. We are not liable for any
                            statements or representations in your Contributions
                            provided by you in any area on the Site. You are
                            solely responsible for your Contributions to the
                            Site and you expressly agree to exonerate us from
                            any and all responsibility and to refrain from any
                            legal action against us regarding your
                            Contributions.
                          </span>
                        </div>
                        <div>
                          <div classnope="block-container if" data-type="if">
                            <div
                              classnope="statement-end-if-in-editor"
                              data-type="close"
                            >
                              <span></span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <br />
                        </div>
                        <div>
                          <br />
                        </div>
                        <div>
                          <div classnope="block-container if" data-type="if">
                            <div data-type="conditional-block">
                              <div
                                classnope="block-component"
                                data-record-question-key="review_option"
                                data-type="statement"
                              >
                                <span></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        classnope="block-container if"
                        data-type="if"
                        id="c954892f-02b9-c743-d1e8-faf0d59a4b70"
                      >
                        <div data-type="conditional-block">
                          <div
                            classnope="block-component"
                            data-record-question-key="mobile_app_option"
                            data-type="statement"
                          >
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span></span>
                    </div>
                    <div data-custom-classnope="heading_1" id="submissions">
                      <strong>
                        <span>
                          <strong>
                            <span>
                              <strong>
                                <span>7.</span>
                              </strong>
                            </span>
                            &nbsp;
                          </strong>
                          SUBMISSIONS
                        </span>
                      </strong>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div data-custom-classnope="body_text">
                      <span>
                        <span>
                          You acknowledge and agree that any questions,
                          comments, suggestions, ideas, feedback, or other
                          information regarding the Site ("Submissions")
                          provided by you to us are non-confidential and shall
                          become our sole property. We shall own exclusive
                          rights, including all intellectual property rights,
                          and shall be entitled to the unrestricted use and
                          dissemination of these Submissions for any lawful
                          purpose, commercial or otherwise, without
                          acknowledgment or compensation to you. You hereby
                          waive all moral rights to any such Submissions, and
                          you hereby warrant that any such Submissions are
                          original with you or that you have the right to submit
                          such Submissions. You agree there shall be no recourse
                          against us for any alleged or actual infringement or
                          misappropriation of any proprietary right in your
                          Submissions.
                        </span>
                      </span>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <span></span>
                    </div>
                    <div data-custom-classnope="heading_1" id="thirdparty">
                      <strong>
                        <span>
                          <strong>
                            <span>
                              <strong>
                                <span>8.</span>
                              </strong>
                            </span>
                            &nbsp;
                          </strong>
                          THIRD-PARTY WEBSITE AND CONTENT
                        </span>
                      </strong>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div data-custom-classnope="body_text">
                      <span>
                        The Site may contain (or you may be sent via the Site)
                        links to other websites ("Third-Party Websites") as well
                        as articles, photographs, text, graphics, pictures,
                        designs, music, sound, video, information, applications,
                        software, and other content or items belonging to or
                        originating from third parties ("Third-Party Content").
                        Such Third-Party Websites and Third-Party Content are
                        not investigated, monitored, or checked for accuracy,
                        appropriateness, or completeness by us, and we are not
                        responsible for any Third-Party Websites accessed
                        through the Site or any Third-Party Content posted on,
                        available through, or installed from the Site, including
                        the content, accuracy, offensiveness, opinions,
                        reliability, privacy practices, or other policies of or
                        contained in the Third-Party Websites or the Third-Party
                        Content. Inclusion of, linking to, or permitting the use
                        or installation of any Third-Party Websites or any
                        Third-Party Content does not imply approval or
                        endorsement thereof by us. If you decide to leave the
                        Site and access the Third-Party Websites or to use or
                        install any Third-Party Content, you do so at your own
                        risk, and you should be aware these Terms of Use no
                        longer govern. You should review the applicable terms
                        and policies, including privacy and data gathering
                        practices, of any website to which you navigate from the
                        Site or relating to any applications you use or install
                        from the Site. Any purchases you make through
                        Third-Party Websites will be through other websites and
                        from other companies, and we take no responsibility
                        whatsoever in relation to such purchases which are
                        exclusively between you and the applicable third party.
                        You agree and acknowledge that we do not endorse the
                        products or services offered on Third-Party Websites and
                        you shall hold us harmless from any harm caused by your
                        purchase of such products or services. Additionally, you
                        shall hold us harmless from any losses sustained by you
                        or harm caused to you relating to or resulting in any
                        way from any Third-Party Content or any contact with
                        Third-Party Websites.
                      </span>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <span>
                        <div classnope="statement-end-if-in-editor"></div>
                      </span>
                    </div>
                    <div>
                      <div
                        classnope="block-container if"
                        data-type="if"
                        id="14038561-dad7-be9d-370f-f8aa487b2570"
                      >
                        <div data-type="conditional-block">
                          <div
                            classnope="block-component"
                            data-record-question-key="advertiser_option"
                            data-type="statement"
                          >
                            <span></span>
                          </div>
                          <div data-type="body">
                            <div
                              data-custom-classnope="heading_1"
                              id="advertisers"
                            >
                              <strong>
                                <span>
                                  <strong>
                                    <span>
                                      <strong>
                                        <span>9.</span>
                                      </strong>
                                    </span>
                                    &nbsp;
                                  </strong>
                                  ADVERTISERS
                                </span>
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div classnope="block-container if" data-type="if">
                        <div data-type="conditional-block">
                          <div data-type="body">
                            <div data-custom-classnope="body_text">
                              <span>
                                We allow advertisers to display their
                                advertisements and other information in certain
                                areas of the Site, such as sidebar
                                advertisements or banner advertisements. If you
                                are an advertiser, you shall take full
                                responsibility for any advertisements you place
                                on the Site and any services provided on the
                                Site or products sold through those
                                advertisements. Further, as an advertiser, you
                                warrant and represent that you possess all
                                rights and authority to place advertisements on
                                the Site, including, but not limited to,
                                intellectual property rights, publicity rights,
                                and contractual rights.
                                <div
                                  classnope="block-container if"
                                  data-type="if"
                                  id="8ec10cc0-11fe-3699-b713-180daf1c979d"
                                >
                                  <div data-type="conditional-block">
                                    <div
                                      classnope="block-component"
                                      data-record-question-key="copyright_agent_option"
                                      data-type="statement"
                                    ></div>
                                  </div>
                                </div>
                              </span>
                              <div
                                classnope="statement-end-if-in-editor"
                                data-type="close"
                              ></div>
                              We simply provide the space to place such
                              advertisements, and we have no other relationship
                              with advertisers.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div classnope="block-container if" data-type="if">
                        <div
                          classnope="statement-end-if-in-editor"
                          data-type="close"
                        >
                          <span></span>
                        </div>
                      </div>
                      <div data-custom-classnope="heading_1" id="sitemanage">
                        <strong>
                          <span>
                            <strong>
                              <span>
                                <strong>
                                  <span>10.</span>
                                </strong>
                              </span>
                              &nbsp;
                            </strong>
                            SITE MANAGEMENT
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          We reserve the right, but not the obligation, to: (1)
                          monitor the Site for violations of these Terms of Use;
                          (2) take appropriate legal action against anyone who,
                          in our sole discretion, violates the law or these
                          Terms of Use, including without limitation, reporting
                          such user to law enforcement authorities; (3) in our
                          sole discretion and without limitation, refuse,
                          restrict access to, limit the availability of, or
                          disable (to the extent technologically feasible) any
                          of your Contributions or any portion thereof; (4) in
                          our sole discretion and without limitation, notice, or
                          liability, to remove from the Site or otherwise
                          disable all files and content that are excessive in
                          size or are in any way burdensome to our systems; and
                          (5) otherwise manage the Site in a manner designed to
                          protect our rights and property and to facilitate the
                          proper functioning of the Site.
                        </span>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div
                        classnope="block-container if"
                        data-type="if"
                        id="bdd90fa9-e664-7d0b-c352-2b8e77dd3bb4"
                      >
                        <div data-type="conditional-block">
                          <div
                            classnope="block-component"
                            data-record-question-key="privacy_policy_option"
                            data-type="statement"
                          >
                            <span></span>
                          </div>
                        </div>
                        <div>
                          <div
                            classnope="block-container if"
                            data-type="if"
                            id="87a7471d-cf82-1032-fdf8-601d37d7b017"
                          >
                            <div data-type="conditional-block">
                              <div
                                classnope="block-component"
                                data-record-question-key="privacy_policy_followup"
                                data-type="statement"
                              >
                                <span></span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div classnope="block-component"></div>
                        <div classnope="block-component">
                          <div classnope="block-container if" data-type="if">
                            <div
                              classnope="statement-end-if-in-editor"
                              data-type="close"
                            >
                              <span></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div classnope="block-container if" data-type="if">
                          <div
                            classnope="statement-end-if-in-editor"
                            data-type="close"
                          >
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div data-custom-classnope="heading_1" id="terms">
                          <strong>
                            <span>
                              <strong>
                                <span>
                                  <strong>
                                    <span>11.</span>
                                  </strong>
                                </span>
                                &nbsp;
                              </strong>
                              TERM AND TERMINATION
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div data-custom-classnope="body_text">
                          <span>
                            These Terms of Use shall remain in full force and
                            effect while you use the Site. WITHOUT LIMITING ANY
                            OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE
                            THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
                            NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
                            SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO
                            ANY PERSON FOR ANY REASON OR FOR NO REASON,
                            INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                            REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN
                            THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
                            REGULATION. WE MAY TERMINATE YOUR USE OR
                            PARTICIPATION IN THE SITE OR DELETE
                            <div
                              classnope="block-container if"
                              data-type="if"
                              id="a6e121c2-36b4-5066-bf9f-a0a33512e768"
                            >
                              <div data-type="conditional-block">
                                <div
                                  classnope="block-component"
                                  data-record-question-key="user_account_option"
                                  data-type="statement"
                                ></div>
                              </div>
                              ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
                              TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                            </div>
                          </span>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div data-custom-classnope="body_text">
                          <span>
                            If we terminate or suspend your account for any
                            reason, you are prohibited from registering and
                            creating a new account under your name, a fake or
                            borrowed name, or the name of any third party, even
                            if you may be acting on behalf of the third party.
                            In addition to terminating or suspending your
                            account, we reserve the right to take appropriate
                            legal action, including without limitation pursuing
                            civil, criminal, and injunctive redress.
                          </span>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div>
                          <br />
                        </div>
                        <div
                          data-custom-classnope="heading_1"
                          id="modifications"
                        >
                          <strong>
                            <span>
                              <span>
                                <strong>
                                  <span>
                                    <strong>
                                      <span>12.</span>
                                    </strong>
                                  </span>
                                  &nbsp;
                                </strong>
                                MODIFICATIONS AND INTERRUPTIONS
                              </span>
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div data-custom-classnope="body_text">
                          <span>
                            We reserve the right to change, modify, or remove
                            the contents of the Site at any time or for any
                            reason at our sole discretion without notice.
                            However, we have no obligation to update any
                            information on our Site. We also reserve the right
                            to modify or discontinue all or part of the Site
                            without notice at any time. We will not be liable to
                            you or any third party for any modification, price
                            change, suspension, or discontinuance of the Site.
                          </span>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div data-custom-classnope="body_text">
                          <span>
                            We cannot guarantee the Site will be available at
                            all times. We may experience hardware, software, or
                            other problems or need to perform maintenance
                            related to the Site, resulting in interruptions,
                            delays, or errors. We reserve the right to change,
                            revise, update, suspend, discontinue, or otherwise
                            modify the Site at any time or for any reason
                            without notice to you. You agree that we have no
                            liability whatsoever for any loss, damage, or
                            inconvenience caused by your inability to access or
                            use the Site during any downtime or discontinuance
                            of the Site. Nothing in these Terms of Use will be
                            construed to obligate us to maintain and support the
                            Site or to supply any corrections, updates, or
                            releases in connection therewith.
                          </span>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div>
                          <br />
                        </div>
                        <div data-custom-classnope="heading_1" id="law">
                          <strong>
                            <span>
                              <span>
                                <strong>
                                  <span>
                                    <strong>
                                      <span>13.</span>
                                    </strong>
                                  </span>
                                  &nbsp;
                                </strong>
                                GOVERNING LAW
                              </span>
                              &nbsp;
                            </span>
                          </strong>
                        </div>
                        <div>
                          <br />
                        </div>
                        <div>
                          <div classnope="block-component">
                            <span></span>
                          </div>
                        </div>
                        <div data-custom-classnope="body_text">
                          <span>
                            These Terms of Use and your use of the Site are
                            governed by and construed in accordance with the
                            laws of
                            <div
                              classnope="block-container if"
                              data-type="if"
                              id="b86653c1-52f0-c88c-a218-e300b912dd6b"
                            >
                              <div data-type="conditional-block">
                                <div
                                  classnope="block-component"
                                  data-record-question-key="governing_law"
                                  data-type="statement"
                                ></div>
                                <div data-type="body">
                                  the State of
                                  <div
                                    classnope="block-container question question-in-editor"
                                    data-id="b61250bd-6b61-32ea-a9e7-4a02690297c3"
                                    data-type="question"
                                  >
                                    Texas
                                  </div>
                                </div>
                              </div>
                              <div
                                classnope="statement-end-if-in-editor"
                                data-type="close"
                              ></div>
                            </div>
                            applicable to agreements made and to be entirely
                            performed within
                            <div
                              classnope="block-container if"
                              data-type="if"
                              id="b86653c1-52f0-c88c-a218-e300b912dd6b"
                            >
                              <div data-type="conditional-block">
                                &nbsp;
                                <span>
                                  <div
                                    classnope="block-container if"
                                    data-type="if"
                                    id="b86653c1-52f0-c88c-a218-e300b912dd6b"
                                  >
                                    <div data-type="conditional-block">
                                      <div
                                        classnope="block-component"
                                        data-record-question-key="governing_law"
                                        data-type="statement"
                                      ></div>
                                      <div data-type="body">
                                        the State of
                                        <div
                                          classnope="block-container question question-in-editor"
                                          data-id="b61250bd-6b61-32ea-a9e7-4a02690297c3"
                                          data-type="question"
                                        >
                                          Texas
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      classnope="statement-end-if-in-editor"
                                      data-type="close"
                                    ></div>
                                  </div>
                                  , without regard to its conflict of law
                                  principles.
                                </span>
                              </div>
                            </div>
                          </span>
                        </div>
                        <div>
                          <br />
                        </div>
                        <div>
                          <br />
                        </div>
                        <div data-custom-classnope="heading_1" id="disputes">
                          <strong>
                            <span>
                              <strong>
                                <span>
                                  <strong>
                                    <span>
                                      <strong>
                                        <span>14.</span>
                                      </strong>
                                    </span>
                                  </strong>
                                </span>
                                &nbsp;
                              </strong>
                              DISPUTE RESOLUTION
                            </span>
                          </strong>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div>
                          <div
                            classnope="block-container if"
                            data-type="if"
                            id="4de367b8-a92e-8bf8-bc2e-013cba6337f8"
                          >
                            <div data-type="conditional-block">
                              <div
                                classnope="block-component"
                                data-record-question-key="dispute_option"
                                data-type="statement"
                              ></div>
                              <div data-type="body">
                                <div data-custom-classnope="heading_2">
                                  <strong>Binding Arbitration</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <br />
                        </div>
                        <div>
                          <div classnope="block-container if" data-type="if">
                            <div data-type="conditional-block">
                              <div data-type="body">
                                <div>
                                  <span></span>
                                </div>
                                <div data-custom-classnope="body_text">
                                  <span>
                                    Any dispute
                                    <span>
                                      arising out of or in connection with this
                                      contract, including any question regarding
                                      its existence, validity or termination,
                                      shall be referred to and finally resolved
                                      by the International Commercial
                                      Arbitration Court under the European
                                      Arbitration Chamber (Belgium, Brussels,
                                      Avenue Louise, 146) according to the Rules
                                      of this ICAC, which, as a result of
                                      referring to it, is considered as the part
                                      of this clause. The number of arbitrators
                                      shall be
                                      <div classnope="question">__________</div>
                                      .
                                      <span>
                                        The seat, or legal place, of arbitration
                                        shall be
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <span>
                                                    <div classnope="question">
                                                      __________
                                                    </div>
                                                    <div classnope="statement-end-if-in-editor"></div>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>{" "}
                                    </span>
                                  </span>
                                  .
                                  <span>
                                    <span>
                                      &nbsp;The language of the proceedings
                                      shall be
                                      <div classnope="question">__________</div>
                                      .
                                    </span>
                                  </span>
                                  The governing law of the contract shall be the
                                  substantive law of
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <div classnope="question">
                                            __________
                                          </div>
                                          <div classnope="statement-end-if-in-editor"></div>{" "}
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  .
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <span>
                          <span>
                            <span></span>
                          </span>
                        </span>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div classnope="block-container if" data-type="if">
                          <div data-type="conditional-block">
                            <div data-type="body">
                              <div data-custom-classnope="heading_2">
                                <strong>Restrictions</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div classnope="block-container if" data-type="if">
                          <div data-type="conditional-block">
                            <div data-type="body">
                              <div data-custom-classnope="body_text">
                                <span>
                                  The Parties agree that any arbitration shall
                                  be limited to the Dispute between the Parties
                                  individually. To the full extent permitted by
                                  law, (a) no arbitration shall be joined with
                                  any other proceeding; (b) there is no right or
                                  authority for any Dispute to be arbitrated on
                                  a class-action basis or to utilize class
                                  action procedures; and (c) there is no right
                                  or authority for any Dispute to be brought in
                                  a purported representative capacity on behalf
                                  of the general public or any other persons.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div classnope="block-container if" data-type="if">
                          <div data-type="conditional-block">
                            <div data-type="body">
                              <div data-custom-classnope="heading_2">
                                <strong>Exceptions to Arbitration</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <div classnope="block-container if" data-type="if">
                          <div data-type="conditional-block">
                            <div data-type="body">
                              <div data-custom-classnope="body_text">
                                <span>
                                  The Parties agree that the following Disputes
                                  are not subject to the above provisions
                                  concerning binding arbitration: (a) any
                                  Disputes seeking to enforce or protect, or
                                  concerning the validity of, any of the
                                  intellectual property rights of a Party; (b)
                                  any Dispute related to, or arising from,
                                  allegations of theft, piracy, invasion of
                                  privacy, or unauthorized use; and (c) any
                                  claim for injunctive relief. If this provision
                                  is found to be illegal or unenforceable, then
                                  neither Party will elect to arbitrate any
                                  Dispute falling within that portion of this
                                  provision found to be illegal or unenforceable
                                  and such Dispute shall be decided by a court
                                  of competent jurisdiction within the courts
                                  listed for jurisdiction above, and the Parties
                                  agree to submit to the personal jurisdiction
                                  of that court.
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            classnope="statement-end-if-in-editor"
                            data-type="close"
                          >
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div>
                        <br />
                      </div>
                      <div data-custom-classnope="heading_1" id="corrections">
                        <strong>
                          <span>
                            <strong>
                              <span>
                                <strong>
                                  <span>15.</span>
                                </strong>
                              </span>
                              &nbsp;
                            </strong>
                            CORRECTIONS
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          There may be information on the Site that contains
                          typographical errors, inaccuracies, or omissions,
                          including descriptions, pricing, availability, and
                          various other information. We reserve the right to
                          correct any errors, inaccuracies, or omissions and to
                          change or update the information on the Site at any
                          time, without prior notice.
                        </span>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div>
                        <br />
                      </div>
                      <div data-custom-classnope="heading_1" id="disclaimer">
                        <strong>
                          <span>
                            <strong>
                              <span>
                                <strong>
                                  <span>16.</span>
                                </strong>
                              </span>
                              &nbsp;
                            </strong>
                            DISCLAIMER
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE
                          BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR
                          SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
                          EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
                          EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND
                          YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE
                          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                          PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO
                          WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                          COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF
                          ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
                          LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
                          MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
                          (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                          WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF
                          THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
                          SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                          AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                          INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
                          THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
                          LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE
                          BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS
                          IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE
                          OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
                          CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE
                          AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE,
                          GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
                          SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
                          THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR
                          MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
                          ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
                          WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
                          BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS
                          OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
                          SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU
                          SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
                          WHERE APPROPRIATE.
                        </span>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div>
                        <br />
                      </div>
                      <div data-custom-classnope="heading_1" id="liability">
                        <strong>
                          <span>
                            <strong>
                              <span>
                                <strong>
                                  <span>17.&nbsp;</span>
                                </strong>
                              </span>
                            </strong>
                            LIMITATIONS OF LIABILITY
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR
                          AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                          DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY,
                          INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING
                          LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER
                          DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE
                          HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                          <div
                            classnope="block-container if"
                            data-type="if"
                            id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                          >
                            <div data-type="conditional-block">
                              <div
                                classnope="block-component"
                                data-record-question-key="limitations_liability_option"
                                data-type="statement"
                              ></div>
                              <div data-type="body">
                                NOTWITHSTANDING ANYTHING TO THE CONTRARY
                                CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY
                                CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF
                                THE ACTION, WILL AT ALL TIMES BE LIMITED TO
                                <div
                                  classnope="block-container if"
                                  data-type="if"
                                  id="73189d93-ed3a-d597-3efc-15956fa8e04e"
                                >
                                  <div data-type="conditional-block">
                                    <div
                                      classnope="block-component"
                                      data-record-question-key="limitations_liability_option"
                                      data-type="statement"
                                    ></div>
                                    <div data-type="body">
                                      <div
                                        classnope="block-container question question-in-editor"
                                        data-id="ae76b3ba-0c65-9cab-85b1-4ac279eef50b"
                                        data-type="question"
                                      >
                                        $0.00 USD
                                      </div>
                                    </div>
                                  </div>
                                  <div data-type="conditional-block">
                                    <div
                                      classnope="block-component"
                                      data-record-question-key="null"
                                      data-type="statement"
                                    ></div>
                                  </div>
                                  . CERTAIN US STATE LAWS AND INTERNATIONAL LAWS
                                  DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
                                  OR THE EXCLUSION OR LIMITATION OF CERTAIN
                                  DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
                                  ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
                                  MAY NOT APPLY TO YOU, AND YOU MAY HAVE
                                  ADDITIONAL RIGHTS.
                                </div>
                              </div>
                              <div
                                classnope="statement-end-if-in-editor"
                                data-type="close"
                              ></div>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div>
                        <br />
                      </div>
                      <div
                        data-custom-classnope="heading_1"
                        id="indemnification"
                      >
                        <strong>
                          <span>
                            <strong>
                              <span>
                                <strong>
                                  <span>18.</span>
                                </strong>
                              </span>
                              &nbsp;
                            </strong>
                            INDEMNIFICATION
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          You agree to defend, indemnify, and hold us harmless,
                          including our subsidiaries, affiliates, and all of our
                          respective officers, agents, partners, and employees,
                          from and against any loss, damage, liability, claim,
                          or demand, including reasonable attorneys’ fees and
                          expenses, made by any third party due to or arising
                          out of:
                          <div
                            classnope="block-container if"
                            data-type="if"
                            id="475fffa5-05ca-def8-ac88-f426b238903c"
                          >
                            <div data-type="conditional-block">
                              <div
                                classnope="block-component"
                                data-record-question-key="user_post_content_option"
                                data-type="statement"
                              ></div>
                            </div>
                            (<span>1</span>) use of the Site; (<span>2</span>)
                            breach of these Terms of Use; (<span>3</span>) any
                            breach of your representations and warranties set
                            forth in these Terms of Use; (<span>4</span>) your
                            violation of the rights of a third party, including
                            but not limited to intellectual property rights; or
                            (<span>5</span>) any overt harmful act toward any
                            other user of the Site with whom you connected via
                            the Site. Notwithstanding the foregoing, we reserve
                            the right, at your expense, to assume the exclusive
                            defense and control of any matter for which you are
                            required to indemnify us, and you agree to
                            cooperate, at your expense, with our defense of such
                            claims. We will use reasonable efforts to notify you
                            of any such claim, action, or proceeding which is
                            subject to this indemnification upon becoming aware
                            of it.
                          </div>
                        </span>
                        <span></span>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div>
                        <br />
                      </div>
                      <div data-custom-classnope="heading_1" id="userdata">
                        <strong>
                          <span>
                            <strong>
                              <span>
                                <strong>
                                  <span>
                                    <strong>
                                      <span>19.</span>
                                    </strong>
                                  </span>
                                </strong>
                                &nbsp;
                              </span>
                            </strong>
                            USER DATA
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          We will maintain certain data that you transmit to the
                          Site for the purpose of managing the performance of
                          the Site, as well as data relating to your use of the
                          Site. Although we perform regular routine backups of
                          data, you are solely responsible for all data that you
                          transmit or that relates to any activity you have
                          undertaken using the Site. You agree that we shall
                          have no liability to you for any loss or corruption of
                          any such data, and you hereby waive any right of
                          action against us arising from any such loss or
                          corruption of such data.
                        </span>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div>
                        <br />
                      </div>
                      <div data-custom-classnope="heading_1" id="electronic">
                        <strong>
                          <span>
                            <strong>
                              <span>
                                <strong>
                                  <span>20.</span>
                                </strong>
                              </span>
                              &nbsp;
                            </strong>
                            ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                            SIGNATURES
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          Visiting the Site, sending us emails, and completing
                          online forms constitute electronic communications. You
                          consent to receive electronic communications, and you
                          agree that all agreements, notices, disclosures, and
                          other communications we provide to you electronically,
                          via email and on the Site, satisfy any legal
                          requirement that such communication be in writing. YOU
                          HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                          CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO
                          ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
                          OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
                          THE SITE. You hereby waive any rights or requirements
                          under any statutes, regulations, rules, ordinances, or
                          other laws in any jurisdiction which require an
                          original signature or delivery or retention of
                          non-electronic records, or to payments or the granting
                          of credits by any means other than electronic means.
                        </span>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <span></span>
                      </div>
                      <div data-custom-classnope="heading_1" id="california">
                        <strong>
                          <span>
                            <strong>
                              <span>
                                <strong>
                                  <span>21.&nbsp;</span>
                                </strong>
                              </span>
                            </strong>
                            CALIFORNIA USERS AND RESIDENTS
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          If any complaint with us is not satisfactorily
                          resolved, you can contact the Complaint Assistance
                          Unit of the Division of Consumer Services of the
                          California Department of Consumer Affairs in writing
                          at 1625 North Market Blvd., Suite N 112, Sacramento,
                          California 95834 or by telephone at (800) 952-5210 or
                          (916) 445-1254.
                        </span>
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <br />
                      </div>
                      <div>
                        <span>
                          <span></span>
                        </span>
                      </div>
                      <div data-custom-classnope="heading_1" id="misc">
                        <strong>
                          <span>
                            <strong>
                              <span>
                                <strong>
                                  <span>22.</span>
                                </strong>
                              </span>
                              &nbsp;
                            </strong>
                            MISCELLANEOUS
                          </span>
                        </strong>
                      </div>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div>
                      <div data-custom-classnope="body_text">
                        <span>
                          These Terms of Use and any policies or operating rules
                          posted by us on the Site or in respect to the Site
                          constitute the entire agreement and understanding
                          between you and us. Our failure to exercise or enforce
                          any right or provision of these Terms of Use shall not
                          operate as a waiver of such right or provision. These
                          Terms of Use operate to the fullest extent permissible
                          by law. We may assign any or all of our rights and
                          obligations to others at any time. We shall not be
                          responsible or liable for any loss, damage, delay, or
                          failure to act caused by any cause beyond our
                          reasonable control. If any provision or part of a
                          provision of these Terms of Use is determined to be
                          unlawful, void, or unenforceable, that provision or
                          part of the provision is deemed severable from these
                          Terms of Use and does not affect the validity and
                          enforceability of any remaining provisions. There is
                          no joint venture, partnership, employment or agency
                          relationship created between you and us as a result of
                          these Terms of Use or use of the Site. You agree that
                          these Terms of Use will not be construed against us by
                          virtue of having drafted them. You hereby waive any
                          and all defenses you may have based on the electronic
                          form of these Terms of Use and the lack of signing by
                          the parties hereto to execute these Terms of Use.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                <i>scroll... it's boring </i> :)
              </p>
            </div>
            <div className="right-sidebar">
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TermsPage;
